<template>
        <div class="checkout__products__card">
        <div class="checkout__products__card__img">
                 <img :src="image">
            </div>
        <div class="checkout__products__card__text">
                <div class="name_price">
                    <h2>{{name}} ({{amount}})</h2>
<!--                     <h2><i class="fa-brands fa-ethereum"></i> {{priceItem}}</h2>
 -->                </div>
        </div>
    </div>
</template>
<script>

export default {
    props:['pcz', 'name', 'image', 'price', 'amount', 'stock', 'maxStock'],
    data(){
        return{
            amountItem: 0,
            priceItem: 0,
        }
    },
    created(){
        this.amountItem = this.$props.amount;
    },
    watch:{
        amountItem(newAmount){
            if(newAmount > this.$props.maxStock){
                this.amountItem = this.$props.maxStock
            }
            this.priceItem = this.amountItem * this.$props.price;
            this.priceItem = this.priceItem.toFixed(1);
        }
    }
}
</script>

<template>
    <main class="imgright">
        <div class="col-5 main-hero">
            <h1>Discover the <br>Pingu Chill Zone</h1>
            <p>
                PCZ is a limited NFT collection with over 10,000 unique digital collectibles. 
                Owning one of these tokens gives you the abillity to use your toke for what ever you want. For example, using it as your profile picture,
                use it as a collecters item, use it as an investment and so much more!
            </p>
            <router-link to="/collection" class="button">Get yours</router-link>
        </div>
            <img src="../assets/collection/006.png" alt="hero">
    </main>
    <PopularProductsComponent/>
    <section class="imgright">
        <div class="col-5">
            <h1>Join our community</h1>
            <p>
                Thanks to all of your support we were able to build an amazing community!
                We have our public community which you can join anytime and then there is our exclusief community for Pingu owners only.
                Have fun and spread the joy!
            </p>
            <a href="https://discord.gg/x3TnnnbpE2" target="_blank" class="button">Join now</a>
        </div>
            <img src="../assets/join.png" alt="hero">
    </section>
</template>

<script>
import PopularProductsComponent from '../components/all/PopularProductsComponent'
export default{
    components:{
        PopularProductsComponent
    },
    inject:[
        'DataPCZ',
        'User'
    ],
    data(){
        return{

        }
    },
}
</script>

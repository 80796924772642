<template>
    <div v-if="popUp">
        <AddedCart 
            @close-alert="closeAlert"
            :pcz="pingu.info.pcz"
            :name="pingu.info.name"
            :amount="amountPingu.amount"
            :image="pingu.info.img"
            :price="pingu.info.price"
            :stock="pingu.info.stock"
            :maxStock="pingu.info.maxStock"
        />
    </div>
     <div class="broodkruimels">
        <a @click="$router.go(-1)"><i class="fa-solid fa-angle-left"></i> Back</a><span>/</span>
        <router-link to="/collection">Collection</router-link><span>/</span>
        <a class="active-broodkruimel">{{pingu.info.name}}</a>
    </div>
    <section class="pingu-detail">
        <div class="product-title" id="product-title-mobile">
            <h1>{{pingu.info.name}}</h1>
            </div>
        <div id="pingu-pcz">
            <img :src="pingu.info.img" :alt="pingu.info.description">
        </div>
        <div class="pingu-detail-text">
            <div class="product-title" id="product-title-desktop">
                <h1>{{pingu.info.name}}</h1>
                <div class="pingu-social">
                    <p>Owned by {{pingu.details.owner}}</p>
                    <div class="pingu-social-flex">
                       <i class="fa-solid fa-eye"></i>
                        <p> {{pingu.details.views}} views</p> 
                    </div>
                    <div class="pingu-social-flex">
                        <i class="fa-solid fa-heart" id=""></i>
                        <p> <span id="favoriet-number">{{pingu.details.likes}}</span> favorites</p>
                    </div>
                </div>
            </div>
            <div class="detail-price">
                <div class="price-container">
                    <p class="cursief mini">Price</p>
                    <div class="price-text-container">
                        <p class="detail-price-text"><i class="fa-brands fa-ethereum"></i> {{pingu.info.price}}</p><p>Current stock: {{ pingu.info.stock }}</p>
                    </div>
                </div>
                <div class="detail-price-buttons">
                    <div class="add_to_cart" v-if="pingu.info.stock > 0">
                        <input type="number" min="1" :max="pingu.info.stock" v-model="amountPingu.amount">
                        <a class="button add-cart" @click="addToCart()"><i class="fa-solid fa-cart-plus"></i>Add to cart</a>
                    </div>
                   <a v-else class="button add-cart">Currently out of stock</a>
                    <div class="price-like-share">
                        <a class="button" id="favoriet"><i class="fa-regular fa-heart"></i> Like</a>
                        <a class="button" id="favoriet-added"><i class="fa-solid fa-heart"></i> Liked</a>
                        <a class="button" id="share-pingu"><i class="fa-solid fa-up-right-from-square"></i> Share</a>
                    </div> 
                </div>
            </div>
            <div class="detail-box">
                <div class="detail-header" id="description-button" @click="toggleDescription">
                    <div class="detail-header-title">
                        <i class="fa-solid fa-align-left"></i><h2>Description</h2>
                    </div>
                        <i class="fa-solid fa-chevron-down" v-if="descriptionStatus"></i>
                        <i class="fa-solid fa-chevron-up" v-else></i>
                </div>
                <div class="details-text" :class="description" id="description">
                    <p>{{pingu.info.description}}</p>
                </div>
                
            </div>
            <div class="detail-box">
                <div class="detail-header" id="tag-button" @click="toggleTags">
                    <div class="detail-header-title">
                        <i class="fa-solid fa-tags"></i><h2>Tags</h2>
                    </div>
                    <i class="fa-solid fa-chevron-down" v-if="tagStatus"></i>
                    <i class="fa-solid fa-chevron-up" v-else></i>
                </div>
                <div class="details-text" :class="tags" id="tag">
                     <div class="tag-box">
                        <p class="mini">Theme</p>
                        <h3>{{pingu.tags.theme}}</h3>
                        <p class="mini">1 Pingu has this tag</p>
                    </div>
                    <div class="tag-box">
                        <p class="mini">Feathers</p>
                        <h3>{{pingu.tags.feathers}}</h3>
                        <p class="mini">1 Pingu has this tag</p>
                    </div>
                    <div class="tag-box">
                        <p class="mini">Beak</p>
                        <h3>{{pingu.tags.beak}}</h3>
                        <p class="mini">1 Pingu has this tag</p>
                    </div>
                    <div class="tag-box">
                        <p class="mini">Hat</p>
                        <h3>{{pingu.tags.hat}}</h3>
                        <p class="mini">1 Pingu has this tag</p>
                    </div>
                    <div class="tag-box">
                        <p class="mini">Backpack</p>
                        <h3>{{pingu.tags.backpack}}</h3>
                        <p class="mini">1 Pingu has this tag</p>
                    </div>
                    <div class="tag-box">
                        <p class="mini">Attribute</p>
                        <h3>{{pingu.tags.attribute}}</h3>
                        <p class="mini">1 Pingu has this tag</p>
                    </div>
                </div>
                
            </div>
            <div class="detail-box">
                <div class="detail-header" id="details-button" @click="toggleDetails">
                    <div class="detail-header-title">
                         <i class="fa-solid fa-circle-info"></i><h2>Details</h2>
                    </div>
                        <i class="fa-solid fa-chevron-down" v-if="detailStatus"></i>
                        <i class="fa-solid fa-chevron-up" v-else></i> 
                   </div>
                <div class="details-text" :class="details" id="details">
                    <div class="details-flexbox">
                        <p>Contract adress</p>
                        <p>{{pingu.details.adres}}</p>
                    </div>
                    <div class="details-flexbox">
                        <p>Token ID</p>
                        <p>{{pingu.details.tokenId}}</p>
                    </div>
                    <div class="details-flexbox">
                        <p>Token standard</p>
                        <p>{{pingu.details.tokenStandard}}</p>
                    </div>
                    <div class="details-flexbox">
                        <p>Blockhain</p>
                        <p>{{pingu.details.blockhain}}</p>
                    </div>
                    <div class="details-flexbox">
                        <p>Metadata</p>
                        <p>{{pingu.details.metadata}}</p>
                    </div>
                    <div class="details-flexbox">
                        <p>Creator</p>
                        <p>{{pingu.details.creator}}</p>
                    </div>
                    <div class="details-flexbox">
                        <p>Creator earnings</p>
                        <p>{{pingu.details.creatorEarnings}}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import AddedCart from '../components/all/addedCart.vue'
export default{
    components:{
        AddedCart
    },
    inject:[
        'DataPCZ',
        'Cart'
    ],
    data(){
        return{
            pingu: [],
            amountPingu: {amount: 1},
            provide: false,
            descriptionStatus: true,
            description: "detail_open",
            tagStatus: false,
            tags: "detail_close",
            detailStatus: false,
            details: "detail_close",
            popUp: false

        }
    },
    created(){
        const id = this.$route.params.pinguName;
        const selectedPingu = this.DataPCZ.find(pcz => pcz.info.name === id);
        this.pingu = selectedPingu;
    },
    methods:{
        closeAlert(){
            this.popUp = false
        },
        addToCart(){
            let status = "pending";
            if(this.Cart.pingu.length === 0){
                this.Cart.pingu.push(this.pingu.info.PCZ);
                this.Cart.amount.push(this.amountPingu.amount);
            } else{
                for(let x = 0; x < this.Cart.pingu.length; x++){
                    if(this.Cart.pingu[x] === this.pingu.info.PCZ){
                        this.Cart.amount[x] += this.amountPingu.amount;
                        status = "done";
                    }
                }
                if(status === "pending"){
                    this.Cart.pingu.push(this.pingu.info.PCZ);
                    this.Cart.amount.push(this.amountPingu.amount);
                }
            }
            for(let i = 0; i < this.DataPCZ.length; i++){
                if(this.DataPCZ[i].info.PCZ === this.pingu.info.PCZ){
                    this.DataPCZ[i].info.stock = this.DataPCZ[i].info.stock - this.amountPingu.amount;
                }
            }
            this.popUp = true;
        },
        toggleDescription(){
            this.descriptionStatus = !this.descriptionStatus;
            if(this.description === "detail_open"){
                this.description = "detail_close"
            } else{
                this.description = "detail_open"
            }
        },
        toggleTags(){
            this.tagStatus = !this.tagStatus;
            if(this.tags === "detail_open"){
                this.tags = "detail_close"
            } else{
                this.tags = "detail_open"
            }
        },
        toggleDetails(){
            this.detailStatus = !this.detailStatus;
            if(this.details === "detail_open"){
                this.details = "detail_close"
            } else{
                this.details = "detail_open"
            }
        }
    }
}
</script>
<style>
.detail_open{
    display: block;
}
.detail_close{
    display: none !important;
}
</style>

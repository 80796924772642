import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'; 
import MainPCZ from './pages/MainPCZ'; 
import CollectionPCZ from './pages/CollectionPCZ'; 
import PinguPCZ from './pages/PinguPCZ'; 
import CartPCZ from './pages/CartPCZ'; 
import CheckoutPCZ from './pages/CheckoutPCZ'; 
import LoginPCZ from './pages/LoginPCZ'; 
import ForgotPasPCZ from './pages/ForgotPasPCZ'; 

const app = createApp(App);

const router = createRouter({
    history: createWebHistory(), 
    routes: [
        {path: '/', component: MainPCZ},
        {path: '/collection', component: CollectionPCZ},
        {path: '/collection/:pinguName', component: PinguPCZ},
        {path: '/cart', component: CartPCZ},
        {path: '/cart/checkout', component: CheckoutPCZ},
        {path: '/login', component: LoginPCZ},
        {path: '/resetpassword', component: ForgotPasPCZ},
        {path: '/:pathMatch(.*)*', redirect: '/'},
    ],
    scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
});

app.use(router); 
app.mount('#app')

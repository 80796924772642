<template>
    <section class="login" @return-checkout="returnCheckout">
        <div class="user-form" id="user-login-js">
            <div id="login">
                <div class="login_header">
                    <h1>Log in</h1>
                    <p v-if="wrong" class="wrong">Email or password is incorrect</p>
                </div>
                <label for="mail">Email address</label><br>
                <input type="email" placeholder="mrsnow@pcz.com" id="mail" name="mail" v-model="mail"><br>
                <label for="password">Password</label><br>
                <input type="password" id="password" name="password" v-model="password">
                <router-link to="/resetpassword" class="mini-link" id="reset-pass">Forgot your password?</router-link><br>
                <a class="button" @click="logIn">Log in</a>
                <a>Don't have an account yet? Sign up!</a>
            </div>
        </div>
        <img src="assets/hank-nobg.png">
    </section>
</template>
<script>
export default {
    inject:[
        'Users',
        'User',
        'Login'
    ],
    data(){
        return{
            mail: "",
            password: "",
            wrong: false
        }
    },
    methods:{
        logIn(){
            for(let i = 0; i < this.Users.length; i++){
                if(this.mail === this.Users[i].mail && this.password === this.Users[i].wachtwoord){
                    this.User.unshift(this.Users[i]);
                    this.Login.login = true;
                    this.$router.push('/cart');
                } else {
                    this.wrong = true
                }
            }
        }
    }
}
</script>
<template>
    <div class="imgleft">
        <div class="collage">
            <router-link :to="nUno"><img :src="pUno" alt=""></router-link>
            <router-link :to="nDos"><img :src="pDos" alt=""></router-link>
            <router-link :to="nTres"><img :src="pTres" alt=""></router-link>
            <router-link :to="nQuatro"><img :src="pQuatro" alt=""></router-link>
        </div>
        <div class="col-5">
            <h1>Say hi, to our most famous pingu's</h1>
            <p>
                Meet <span class="capitalize">{{xUno}}</span>, <span class="capitalize">{{xDos}}</span>, <span class="capitalize">{{xTres}}</span> and <span class="capitalize">{{xQuatro}}</span>
                our most popular Pingu's from the Pingu Chill Zone collection. Currently they have the highest stock because they are the most wanted so don't wait and grab your
                favorite! Want to meet all their friends? Check them out in our collection!
            </p>
            <router-link to="/collection" class="button">Meet the others</router-link>
        </div>
    </div>
</template>
<script>
export default {
    inject:[
        'DataPCZ',
        'Cart'
    ],
    created(){
        let uno = 0;
        let dos = 0;
        let tres = 0;
        let quatro = 0;

        let pUno;
        let pDos;
        let pTres;
        let pQuatro;

        let nUno;
        let nDos;
        let nTres;
        let nQuatro;

        for (let index = 0; index < this.DataPCZ.length; index++) {
            let stock = this.DataPCZ[index].info.maxStock;
            let pinguID = this.DataPCZ[index].info.img;
            let pinguName = this.DataPCZ[index].info.name;
            if(stock >= uno){
                dos = uno;
                pDos = pUno;
                nDos = nUno;
                uno = stock;
                pUno = pinguID;
                nUno = pinguName;
            } else if (stock >= dos){
                tres = dos;
                pTres = pDos;
                nTres = nDos;
                dos = stock;
                pDos = pinguID;
                nDos = pinguName;
            } else if (stock >= tres){
                quatro = tres;
                pQuatro = pTres;
                nQuatro = nTres;
                tres = stock;
                pTres = pinguID;
                nTres = pinguName;
            } else if (stock >= quatro){
                quatro = stock;
                pQuatro = pinguID;
                nQuatro = pinguName;
            }
        }
        this.pUno = pUno;
        this.pDos = pDos;
        this.pTres = pTres;
        this.pQuatro = pQuatro;

        this.nUno = "/collection/" + nUno;
        this.nDos = "/collection/" + nDos;
        this.nTres = "/collection/" + nTres;
        this.nQuatro = "/collection/" + nQuatro;

        this.xUno = nUno;
        this.xDos = nDos;
        this.xTres = nTres;
        this.xQuatro = nQuatro;

        
    }
}
</script>
<template>
    <section class="login">
    <div class="user-form" id="user-login-js">
     <div id="resetpassword">
                <h1>Reset password</h1>
                <label for="mail">Email address</label><br>
                <input type="email" placeholder="mrsnow@pcz.com" id="mail" name="password"><br>
                <router-link to="/login" class="mini-link" id="nevermind">Never mind, I remember it again!</router-link>
                <a class="button">Send Reset link</a>
            </div>
        </div>
        <img src="assets/hank-nobg.png">
        </section>
</template>
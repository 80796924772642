<template>
    <div class="alert_container" @click="closeAlert">
        <div class="alert_inhoud">
            <i class="fa-solid fa-x" id="close_alert" @click="closeAlert"></i>
            <h1>Added to your cart:</h1>
            <div class="content">
                <CheckoutCard 
                    :pcz="pcz"
                    :name="name"
                    :amount="amount"
                    :image="image"
                    :price="price"
                    :stock="stock"
                    :maxStock="maxStock"
                />
                <div class="buttons">
                    <router-link to="/collection" class="button">Keep Shopping</router-link>
                    <router-link to="/cart" class="button-dark">Checkout</router-link>
                </div>
            </div>
            </div>
    </div>
</template>
<script>
import CheckoutCard from './CheckoutCard.vue'
export default {
    components:{
        CheckoutCard
    },
    props:['pcz', 'name', 'image', 'price', 'amount', 'stock', 'maxStock'],
    methods:{
        closeAlert(){
            this.$emit("close-alert");
        }
    }

}
</script>
<template>
     <section class="checkout" >
        <ConfirmPCZ v-if="payment" :bill="bill" :delivery="delivery" :billing="billing"/>
        <div class="user-form" id="user-login-js" v-else>
            <h1>Checkout</h1>
            <div class="checkout_form">
                <h2>Delivery Adress</h2>
                <div class="form_group">
                    <div class="form_group_label">
                        <label for="fname">Firstname</label><br>
                        <input type="text" placeholder="Eros" id="fname" name="fname" v-model="delivery.firstname">
                    </div>
                    <div class="form_group_label">
                        <label for="lname">Lastname</label><br>
                        <input type="text" placeholder="Pingu" id="lname" name="lname" v-model="delivery.lastname">
                    </div>
                </div>
                <label for="mail">Mail</label><br>
                <input type="text" placeholder="eros.pingu@pcz.be" id="mail" name="mail" v-model="delivery.mail">
                <label for="street">Street Adress</label><br>
                <input type="text" placeholder="Pingustreet 7" id="street" name="street" v-model="delivery.street">
                <div class="form_group">
                    <div class="form_group_label">
                        <label for="city">City</label><br>
                        <input type="text" placeholder="Pingu Town" id="city" name="city" v-model="delivery.city"><br>
                    </div>
                    <div class="form_group_label">
                        <label for="postcode">Postcode</label><br>
                        <input type="text" placeholder="0718" id="postcode" name="postcode" v-model="delivery.code"><br>
                    </div>
                </div>
                <CountriesPCZ />
                <input type="checkbox" id="bill" name="bill" v-model="bill">
                <label for="bill"> Different billing address</label>
            </div>
            <div class="checkout_form" v-if="bill">
                <h2>Billing Adress</h2>
               <div class="form_group">
                    <div class="form_group_label">
                        <label for="fname">Firstname</label><br>
                        <input type="text" placeholder="Eros" id="fname" name="fname" v-model="billing.firstname">
                    </div>
                    <div class="form_group_label">
                        <label for="lname">Lastname</label><br>
                        <input type="text" placeholder="Pingu" id="lname" name="lname" v-model="billing.lastname">
                    </div>
                </div>
                <label for="mail">Mail</label><br>
                <input type="text" placeholder="eros.pingu@pcz.be" id="mail" name="mail" v-model="billing.mail">
                <label for="street">Street Adress</label><br>
                <input type="text" placeholder="Pingustreet 7" id="street" name="street" v-model="billing.street">
                <div class="form_group">
                    <div class="form_group_label">
                        <label for="city">City</label><br>
                        <input type="text" placeholder="Pingu Town" id="city" name="city" v-model="billing.city"><br>
                    </div>
                    <div class="form_group_label">
                        <label for="postcode">Postcode</label><br>
                        <input type="text" placeholder="0718" id="postcode" name="postcode" v-model="billing.code"><br>
                    </div>
                </div>
                <CountriesPCZ />
            </div>
            <a class="button" @click="payCart">Continue to payment</a>
        </div>
        <div class="checkout__items">
            <div class="cart__checkout">
            <h1>Summary</h1>
            <div>
                <div class="checkout__price">
                    <p>Items ({{ cartSize }})</p>
                    <h2><i class="fa-brands fa-ethereum"></i> {{ items }}</h2>
                </div>
                <div class="checkout__price">
                    <p>VAT</p>
                    <h2><i class="fa-brands fa-ethereum"></i> {{ vat }}</h2>
                </div>
                <div class="checkout__total">
                    <h2>Totaal</h2>
                    <h1><i class="fa-brands fa-ethereum"></i> {{ total }}</h1>
                </div>
            </div>
        </div>
            <CheckoutCard
                v-for="pingu in pinguCart"
                :key="pingu.id"
                :pcz="pingu.info.PCZ"
                :name="pingu.info.name"
                :image="pingu.info.img"
                :price="pingu.info.price"
                :amount="pingu.info.amount"
                :stock="pingu.info.stock"
                :maxStock="pingu.info.maxStock"
            />
        </div>
    </section>
</template>
<script>
import CheckoutCard from '../components/all/CheckoutCard.vue'
import CountriesPCZ from '../components/all/CountriesPCZ.vue'
import ConfirmPCZ from '../components/all/ConfirmPCZ.vue'

export default {
    components:{
        CheckoutCard,
        CountriesPCZ,
        ConfirmPCZ
    },
    inject:[
        'DataPCZ',
        'Cart',
        'Login',
        'User'
    ],
    data(){
        return{
            bill: false,
            cartStatus: false,
            pinguCart: [],
            cartSize: 0,
            items: 0,
            vat: 0,
            total: 0,
            delivery:{
                firstname: "",
                lastname: "",
                mail:"",
                street: "",
                city: "",
                code: "",
            },
            billing:{
                firstname: "",
                lastname: "",
                mail:"",
                street: "",
                city: "",
                code: "",
            },
            payment: false
        }
    },
    methods:{
        payCart(){
            let counter = 0;
            if(this.delivery.firstname.trim() === ""){
                console.log("invalid");
            } else {
                counter++
            }
            if(this.delivery.lastname.trim() === ""){
                console.log("invalid");
            } else {
                counter++
            }
            if(this.delivery.mail.trim() === ""){
                console.log("invalid");
            } else {
                counter++
            }
            if(this.delivery.street.trim() === ""){
                console.log("invalid");
            } else {
                counter++
            }
            if(this.delivery.city.trim() === ""){
                console.log("invalid");
            } else {
                counter++
            }
            if(this.delivery.code.trim() === ""){
                console.log("invalid");
            } else {
                counter++
            }
            if(this.billing.firstname.trim() === ""){
                console.log("invalid");
            } else {
                counter++
            }
            if(this.billing.lastname.trim() === ""){
                console.log("invalid");
            } else {
                counter++
            }
            if(this.billing.mail.trim() === ""){
                console.log("invalid");
            } else {
                counter++
            }
            if(this.billing.street.trim() === ""){
                console.log("invalid");
            } else {
                counter++
            }
            if(this.billing.city.trim() === ""){
                console.log("invalid");
            } else {
                counter++
            }
            if(this.billing.code.trim() === ""){
                console.log("invalid");
            } else {
                counter++
            }
            if( this.bill && counter === 12){
                //this.$router.push('/cart/confirmed');
                this.payment = true;
            } else if (this.bill === false && counter === 6){
                //this.$router.push('/cart/confirmed');
                this.payment = true;
            }
        }
    },
    created(){
        let loginStatus = this.Login.login;
        if(loginStatus){
            this.delivery.firstname = this.User[0].firstname;
            this.delivery.lastname = this.User[0].lastname;
            this.delivery.mail = this.User[0].mail;
            this.delivery.street = this.User[0].street;
            this.delivery.city = this.User[0].city;
            this.delivery.code = this.User[0].code;
            if(this.Cart.pingu.length === 0){
            this.$router.push('/');
        } else {
            if(this.Cart.pingu.length > 0){
                this.cartStatus = true;
                for(let i = 0; i < this.Cart.pingu.length; i++){
                for(let p = 0; p < this.DataPCZ.length; p++)
                if(this.DataPCZ[p].info.PCZ === this.Cart.pingu[i]){
                    this.pinguCart.push(this.DataPCZ[p]);
                    this.pinguCart[i].info.amount = this.Cart.amount[i];
                    this.cartSize += this.pinguCart[i].info.amount;
                }
                }
                for(let cart = 0; cart < this.pinguCart.length; cart++){
                    this.items += this.pinguCart[cart].info.price  * this.pinguCart[cart].info.amount;
                    this.vat += this.pinguCart[cart].info.price  * this.pinguCart[cart].info.amount / 100 * this.pinguCart[cart].info.btw;
                }
                this.vat = parseFloat(this.vat);
                this.items = parseFloat(this.items);
                this.total = parseFloat(this.total);
                this.items = this.items - this.vat;
                this.total = this.items + this.vat;
                this.items = this.items.toFixed(3);
                this.vat = this.vat.toFixed(3);
                this.total = this.total.toFixed(1);
            } else {
                this.cartStatus = false;
            }
        }
        } else {
            this.$router.push('/login');
        }
    }
}
</script>

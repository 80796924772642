<template>
    <footer>
        <div>
            <a href="https://discord.gg/x3TnnnbpE2" target="_blank"><i class="fa-brands fa-discord"></i></a>
            <a href="https://twitter.com" target="_blank"><i class="fa-brands fa-twitter"></i></a>
            <a href="https://www.instagram.com/pingu_chill_zone/" target="_blank"><i class="fa-brands fa-square-instagram"></i></a>
            <a href="mailto:info@pcz.be" target="_blank"><i class="fa-solid fa-envelope"></i></a>
        </div>
        <p>&copy; Jelle Schevernels 2024</p>
    </footer>
</template>

<script>

export default({

})
</script>

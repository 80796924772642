<template>
  <NavbarPCZ @logout-alert="logOutAlert" />
      <router-view :about="about" :collection="colleciton" :pingu="pingu" :cart="cart"></router-view>
  <FooterPCZ />
</template>

<script>
import DataJSON from './collection.json'
import DataUser from './users.json'
import NavbarPCZ from './components/nav/NavbarPCZ.vue';
import FooterPCZ from './components/nav/FooterPCZ.vue';
export default {
    components:{
      NavbarPCZ,
      FooterPCZ
    },
    data(){
      return{
        data: DataJSON.pinguChillZone,
        users: DataUser.users,
        user: [],
        login: {
          login: false
        },
        cart: {
                pingu: [],
                amount: []
            }
      }
    },
    provide(){
      return{
        DataPCZ: this.data,
        Cart: this.cart,
        Users: this.users,
        User: this.user,
        Login: this.login
      }
    },
    methods:{
      logOutAlert(){

      }
    }
}
</script>

<style>

</style>

<template>
        <input type="checkbox" :id="tag + filterOption" :name="filterOption" @click="startFilter(tag, filterOption)" />
        <label :for="tag + filterOption">{{ filterOption }}</label>
</template>
<script>
export default {
        props:['filterOption', 'tag'],
        methods:{
                startFilter(tag, filterOption){
                        let selectedFilter = tag + filterOption;
                        this.$emit('emit-filter', selectedFilter);
                }       
        }        
}
</script>

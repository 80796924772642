<template>
    <nav>
        <a id="nav-logo"><img src="../../assets/logo.png" alt="PZC Logo"></a>
        <p id="burger" @click="burgerIconClick"><i :class="burgerIcon"></i></p>
        <div :class="burgerMenu">
            <ul>
                <li><router-link to="/" @click="menuClick()">About</router-link></li>   
                <li><router-link to="/collection" @click="menuClick()">Collection</router-link></li>   
                <li><router-link to="/cart" @click="menuClick()"><i class="fa-solid fa-cart-shopping" @nav-cart="navCart"></i> Cart <span v-if="cartCounter > 0">({{ cartCounter }})</span></router-link></li>
                <li v-if="login"><a @click="logOut"><i class="fa-solid fa-circle-user"></i> {{User[0].firstname}}</a></li>
                <li v-else><router-link to="/login" @click="menuClick()"><i class="fa-solid fa-circle-user"></i> Log In</router-link></li>
             </ul>
             <footer id="footer-nav">
                <div>
                    <a href="https://discord.gg/x3TnnnbpE2" target="_blank"><i class="fa-brands fa-discord"></i></a>
                    <a href="https://twitter.com" target="_blank"><i class="fa-brands fa-twitter"></i></a>
                    <a href="https://instagram.com" target="_blank"><i class="fa-brands fa-square-instagram"></i></a>
                    <a href="mailto:info@jellecreations.be" target="_blank"><i class="fa-solid fa-envelope"></i></a>
                </div>
                <p>&copy; Jelle Schevernels</p>
            </footer>
        </div>
    </nav>
</template>

<script>
export default({
    inject:[
        'Cart',
        'User',
        'Login'
    ],
    data(){
        return{
           burgerIcon: "fa-solid fa-bars",
           burgerStatus: false,
           cartCounter: 0,
           login: this.Login.login
        }  
    },
    computed:{
        burgerMenu(){
            let burgerClass;
            if(this.burgerStatus){
                burgerClass = "burger__open";
            } else{
                burgerClass = "burger__close";
            }
            return burgerClass;
        }
    },
    methods:{
        logOut(){
            this.User.splice(0, 0);
            console.log(this.User);
            this.Login.login = false;
            this.login = false;
        },
        burgerIconClick(){
            if(this.burgerIcon === "fa-solid fa-bars"){
                this.burgerIcon = "fa-solid fa-xmark";
                this.burgerStatus = !this.burgerStatus
            } else if (this.burgerIcon === "fa-solid fa-xmark"){
                this.burgerIcon = "fa-solid fa-bars"; 
                this.burgerStatus = !this.burgerStatus
            }
        },
        menuClick(){
            this.burgerStatus = false;
            if(this.burgerIcon === "fa-solid fa-bars"){
                this.burgerIcon = "fa-solid fa-xmark";
            } else if (this.burgerIcon === "fa-solid fa-xmark"){
                this.burgerIcon = "fa-solid fa-bars"; 
            }
        }
    },
    watch:{
        Cart: {
        handler(newCart){
            this.cartCounter = 0;
          for(let i = 0; i < newCart.amount.length; i++){
            this.cartCounter += newCart.amount[i];
          }
        },
        deep: true
        },
        User: {
        handler(newUser){
            if(this.User != []){
                console.log(newUser);
                this.Login.login = true;
                this.login = true; 
            }
        },
        deep: true
        }
    },
})
</script>
<style>
a.router-link-active{
    border-bottom: 3px solid #DEE28A;
}
@media screen and (max-width: 767px){
    .burger__close{
        height: 100vh;
        display: block;
        position: absolute;
        background-color: #EBFFFD;
        top: 100vh;
        transition: 0.5s ease-out;
    }
    .burger__open{
        height: 100vh;
        display: block;
        position: absolute;
        top: 4em;
        transition: 0.5s ease-out;
        background-color: #EBFFFD;
    } 
}
</style>
<template>
     <section class="cart" v-if="cartStatus">
        <div class="cart__products">
            <CartPCZ 
                @delete-item="deleteItem"
                @number-change="numberChange"
                v-for="pingu in pinguCart"
                :key="pingu.id"
                :pcz="pingu.info.PCZ"
                :name="pingu.info.name"
                :image="pingu.info.img"
                :price="pingu.info.price"
                :amount="pingu.info.amount"
                :stock="pingu.info.stock"
                :maxStock="pingu.info.maxStock"
            />
        </div>
        <div class="cart__checkout">
            <h1>Summary</h1>
            <div>
                <div class="checkout__price">
                    <p>Items ({{ cartSize }})</p>
                    <h2><i class="fa-brands fa-ethereum"></i> {{ items }}</h2>
                </div>
                <div class="checkout__price">
                    <p>VAT</p>
                    <h2><i class="fa-brands fa-ethereum"></i> {{ vat }}</h2>
                </div>
                <div class="checkout__total">
                    <h2>Totaal</h2>
                    <h1><i class="fa-brands fa-ethereum"></i> {{ total }}</h1>
                </div>
                <div class="checkout__button">
                    <router-link to="cart/checkout" class="button">Checkout</router-link>
                </div>
            </div>
        </div>
     </section>
     <section class="imgright cart_empty" v-else>
        <div class="col-5">
            <h1>Oops! Your cart is empty</h1>
            <p>
                It looks like you didn't add any Pingu's to your cart. Visit our shop to add some.
            </p>
            <router-link to="/collection" class="button">Go to shop</router-link>
        </div>
        <img src="../assets/collection/001.png" alt="logo">
    </section>
</template>
<script>
import CartPCZ from '../components/all/CartCard.vue';
export default{
    inject:[
        'DataPCZ',
        'Cart'
    ],
    components:{
        CartPCZ
    },
    data(){
        return{
            cartStatus: false,
            pinguCart: [],
            cartSize: 0,
            items: 0,
            vat: 0,
            total: 0
        }
    },
    methods:{
        deleteItem(pinguID){
            let amount;
            for(let i = 0; i < this.pinguCart.length; i++){
                if(pinguID === this.pinguCart[i].info.PCZ){
                    this.pinguCart.splice(i, 1);
                    console.log(this.pinguCart);
                    for(let y = 0; y < this.Cart.pingu.length; y++){
                        if(this.Cart.pingu[y] === pinguID){
                            amount = this.Cart.amount[y];
                            this.Cart.pingu.splice(y, 1);
                            this.Cart.amount.splice(y, 1);
                            console.log(this.Cart);
                        }
                    }
                    for(let x = 0; x < this.DataPCZ.length; x++){
                        if(this.DataPCZ[x].info.PCZ === pinguID){
                            this.DataPCZ[x].info.stock = this.DataPCZ[x].info.stock + amount;
                        }
                    }
                }
            }
            this.updateCart();

        },
        numberChange(amountItem, pcz){
            console.log(amountItem);
            for(let i = 0; i < this.pinguCart.length; i++){
                if(pcz === this.pinguCart[i].info.PCZ){
                    this.pinguCart[i].info.amount = amountItem;
                    for(let y = 0; y < this.Cart.pingu.length; y++){
                        if(this.Cart.pingu[y] === pcz){
                            this.Cart.amount[y] = amountItem;
                        }
                    }
                for(let x = 0; x < this.DataPCZ.length; x++){
                    if(this.DataPCZ[x].info.PCZ === pcz){
                            this.DataPCZ[x].info.stock = this.DataPCZ[x].info.maxStock - amountItem;                      
                    }
                }
                }
            }
            this.updateCart();
        },
        updateCart(){
            this.pinguCart = [];
            this.cartSize = 0;
            this.items = 0;
            this.vat = 0;
            this.total = 0;
            if(this.Cart.pingu.length > 0){
                this.cartStatus = true;
                for(let i = 0; i < this.Cart.pingu.length; i++){
                for(let p = 0; p < this.DataPCZ.length; p++)
                if(this.DataPCZ[p].info.PCZ === this.Cart.pingu[i]){
                    this.pinguCart.push(this.DataPCZ[p]);
                    this.pinguCart[i].info.amount = this.Cart.amount[i];
                    this.cartSize += this.pinguCart[i].info.amount;
                }
                }
                for(let cart = 0; cart < this.pinguCart.length; cart++){
                    this.items += this.pinguCart[cart].info.price  * this.pinguCart[cart].info.amount;
                    this.vat += this.pinguCart[cart].info.price  * this.pinguCart[cart].info.amount / 100 * this.pinguCart[cart].info.btw;
                }
                this.vat = parseFloat(this.vat);
                this.items = parseFloat(this.items);
                this.total = parseFloat(this.total);
                this.items = this.items - this.vat;
                this.total = this.items + this.vat;
                this.items = this.items.toFixed(3);
                this.vat = this.vat.toFixed(3);
                this.total = this.total.toFixed(1);
            } else {
                this.cartStatus = false;
            }
        }
            
    },
    created(){
        this.updateCart();
    }

}
</script>

<template>
    <router-link :to="hyper" class="pcz-card">
        <div class="pcz-card-img">
            <img :src="img" :alt="description">
        </div>
        <div class="pcz-card-text">
            <h2>{{ name }}</h2>
            <p>{{ intro }}</p>
            <br>
            <div class="pcz-card-pricebox">
                <div>
                    <p class="cursief">Price</p>
                    <p class="pcz-card-price"><i class="fa-brands fa-ethereum"></i> {{ price }}</p>
                </div>
                <p class="add-cart-button" v-if="stock > 0"><i class="fa-solid fa-cart-plus"></i></p>
                <p class="add-cart-button" v-else><i class="fa-solid fa-ban"></i></p>
            </div>
        </div>
    </router-link>
</template>
<script>
export default{
    props:['img', 'name', 'intro', 'price', 'stock', 'hyper'],
    data(){
        return{
            //hyper: "/collection/" + this.name
        }
    }
}
</script>

<template>
    <section class="shop">
        <div class="products">
            <div class="products-title">
                <h1>Pingu Chill Zone Collection</h1>
                <p>PCZ is a limited NFT collection with over 10,000 unique digital collectibles. Owning one of these tokens gives you the abillity to use your toke for what ever you want. For example, using it as your profile picture, as a collecters item, as an investment and so much more!</p>
            </div>
            <div class="specs-collection">  
                <FilterPCZ @filter-collection="filterCollection" @remove-filters="RemoveFilters" @zoek-naam="searchPingu" :aantalFilters="selectedFilters.length"/>  
                <div class="collection" v-if="filteren">
                    <CardPCZ 
                        v-for="pingu in filterDataPZC.slice(start, end)"
                        :key="pingu.id"
                        :img="pingu.info.img"
                        :name="pingu.info.name"
                        :intro="pingu.info.intro"
                        :price="pingu.info.price"
                        :stock="pingu.info.stock"
                        :hyper="pingu.info.hyper"
                    ></CardPCZ>
                </div>
                <div class="collection" v-else>
                   <CardPCZ 
                        v-for="pingu in DataPCZ.slice(start, end)" 
                        :key="pingu.id"
                        :img="pingu.info.img"
                        :name="pingu.info.name"
                        :intro="pingu.info.intro"
                        :price="pingu.info.price"
                        :stock="pingu.info.stock"
                        :hyper="pingu.info.hyper"
                    ></CardPCZ>
                </div>
            </div>
            <div class="pages">
                <a href="#" @click="pageDown" v-if="page > 1"><i class="fa-solid fa-angles-left"></i></a>
                <a href="#" class="active-page">{{ page }}</a>
                <a href="#" @click="pageUp" v-if="page < allPages.length"><i class="fa-solid fa-angles-right"></i></a>
            </div>
        </div>
    </section>
</template>

<script>
import CardPCZ from '../components/all/CardPCZ.vue'
import FilterPCZ from '../components/all/FilterPCZ.vue'

export default{
    inject:[
        'DataPCZ'
    ],
    components:{
        CardPCZ,
        FilterPCZ
    },
    data(){
        return{
            filteren: false,
            search: String,
            selectedFilters: [],
            filterDataPZC: [],
            allPages: [],
            page: 1,
            start: 0,
            end: 6
        }
    },
    methods:{
        pageDown(){
            this.page--;
            this.start -= 6;
            this.end -= 6;
        },
        pageUp(){
            this.page++;
            this.start += 6;
            this.end += 6;
        },
        searchPingu(name){
            this.filterDataPZC = [];
            let aantal = name.length;
            if(name === ""){
                this.filteren = false;
                this.page = 1;
                this.start = 0;
                this.end = 6;
                let pinguS = this.DataPCZ.length;
                let pages = pinguS / 6 + 0.49;
                pages = Math.round(pages);

                for(let i = 0; i < pages; i++){
                    this.allPages.push(i + 1);
                }
            } else{
                for(let i = 0; i < this.DataPCZ.length; i++){
                  console.log(this.DataPCZ[i].info.name.slice(0, aantal));
                    if(this.DataPCZ[i].info.name.slice(0, aantal) === name){
                        this.filterDataPZC.push(this.DataPCZ[i]);
                    }
                    this.filteren = true;
                    this.allPages = [];
                    this.page = 1;
                    this.start = 0;
                    this.end = 6;
                    let pinguS = this.filterDataPZC.length;
                    let pages = pinguS / 6 + 0.49;
                    pages = Math.round(pages);

                    for(let i = 0; i < pages; i++){
                        this.allPages.push(i + 1);
                    }
                }
            }
            
        },
        filterCollection(selectedFilter){
            this.filterDataPZC = [];
            let currentStatus = "pending";
            if(this.selectedFilters.length === 0){
                this.selectedFilters.push(selectedFilter);
                console.log("filters gestart");
                console.log(selectedFilter + " toegevoegd");
                currentStatus = "done";
                this.filteren = true;
            } else{
                for(let i = 0; i < this.selectedFilters.length; i++){
                    if(selectedFilter === this.selectedFilters[i]){
                        this.selectedFilters.splice(i, 1);
                        console.log(selectedFilter + " verwijderd");
                        currentStatus = "done";
                    }
                }
            }
            if(currentStatus === "pending"){
                this.selectedFilters.push(selectedFilter);
                console.log(selectedFilter + " toegevoegd");
            }

            for(let pingu = 0; pingu < this.DataPCZ.length; pingu++){
                for(let filter = 0; filter < this.selectedFilters.length; filter++){
                    if(this.selectedFilters[filter].charAt(0) === "1"){
                        if(this.DataPCZ[pingu].tags.theme === this.selectedFilters[filter].slice(1)){
                            this.filterDataPZC.push(this.DataPCZ[pingu]);
                        }
                    }
                    if(this.selectedFilters[filter].charAt(0) === "2"){
                        if(this.DataPCZ[pingu].tags.attribute === this.selectedFilters[filter].slice(1)){
                            this.filterDataPZC.push(this.DataPCZ[pingu]);
                        }
                    }
                    if(this.selectedFilters[filter].charAt(0) === "3"){
                        if(this.DataPCZ[pingu].tags.hat === this.selectedFilters[filter].slice(1)){
                            this.filterDataPZC.push(this.DataPCZ[pingu]);
                        }
                    }
                    if(this.selectedFilters[filter].charAt(0) === "4"){
                        if(this.DataPCZ[pingu].tags.feathers === this.selectedFilters[filter].slice(1)){
                            this.filterDataPZC.push(this.DataPCZ[pingu]);
                        }
                    }
                    if(this.selectedFilters[filter].charAt(0) === "5"){
                        if(this.DataPCZ[pingu].tags.beak === this.selectedFilters[filter].slice(1)){
                            this.filterDataPZC.push(this.DataPCZ[pingu]);
                        }
                    }
                    if(this.selectedFilters[filter].charAt(0) === "6"){
                        if(this.DataPCZ[pingu].tags.backpack === this.selectedFilters[filter].slice(1)){
                            this.filterDataPZC.push(this.DataPCZ[pingu]);
                        }
                    }
                    if(this.selectedFilters[filter].charAt(0) === "7"){
                        if(this.selectedFilters[filter].slice(1) === "Yes"){
                            if(this.DataPCZ[pingu].info.stock > 0){
                                this.filterDataPZC.push(this.DataPCZ[pingu]);
                            }
                        }
                    }
                } 
            }
            this.filterDataPZC = this.filterDataPZC.filter((item, index) => this.filterDataPZC.indexOf(item) === index);
            this.filterDataPZC.sort();
            if(this.selectedFilters.length === 0){
                this.filteren = false;
            }
            this.allPages = [];
            this.page = 1;
            this.start = 0;
            this.end = 6;
            let pinguS = this.filterDataPZC.length;
            let pages = pinguS / 6 + 0.49;
            pages = Math.round(pages);

            for(let i = 0; i < pages; i++){
                this.allPages.push(i + 1);
            }
        },
        RemoveFilters(){
            this.selectedFilters = [];
            this.filterDataPZC = [];
            this.filteren = false;
        }
    },
    watch:{

    },
    created(){
        let pinguS = this.DataPCZ.length;
        let pages  = pinguS / 6 + 0.50;
        pages = Math.round(pages);

        for(let i = 0; i < pages; i++){
            this.allPages.push(i + 1);
        }
    }
}
</script>

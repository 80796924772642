<template>
        <div class="user-form confirmed" id="user-login-js">
            <h1>Thank you, <span>{{delivery.firstname}}</span>!</h1>
            <p>We will deliver your Pingu's as soon as possible. If you have any questions about the delivery you can alwasy contact us at info@pcz.be. We will send you an e-mail to confirm your order and with an track and trace code.</p>
            <div class="gegevens_box">
                <h2>Delivery</h2>
                <div>
                    <p>Full Name</p>
                    <p class="gegevens">{{delivery.firstname + " " + delivery.lastname}}</p>
                </div>
                <div>
                    <p>Adress</p>
                    <p class="gegevens">{{delivery.street + ", " + delivery.code + " " + delivery.city}}</p>
                </div>
                <div>
                    <p>E-mail</p>
                    <p class="gegevens">{{delivery.mail}}</p>
                </div>
            </div>
            <div class="gegevens_box" v-if="bill">
                <h2>Billing</h2>
                <div>
                    <p>Full Name</p>
                    <p class="gegevens">{{billing.firstname + " " + billing.lastname}}</p>
                </div>
                <div>
                    <p>Adress</p>
                    <p class="gegevens">{{billing.street + ", " + billing.code + " " + billing.city}}</p>
                </div>
                <div>
                    <p>E-mail</p>
                    <p class="gegevens">{{billing.mail}}</p>
                </div>
            </div>
            </div>
</template>
<script>
export default {
    props:["bill", "delivery", "billing"],
    inject:[
        'DataPCZ',
        'Cart'
    ],
    data(){
        return{
            pinguCart: [],
            cartSize: 0,
            items: 0,
            vat: 0,
            total: 0,
        }
    },
    methods:{
        deliveryUser(user, pinguCart){
            console.log(user);
            console.log(pinguCart);
        }
    },
    created(){
            this.Cart.pingu = [];
            this.Cart.amount = [];
        }
            
    }
</script>
<template>
        <div class="cart__products__card">
        <div class="cart__products__card__img">
                 <img :src="image">
            </div>
        <div class="cart__products__card__text">
                <div class="name_price">
                    <h2>{{name}} ({{amount}})</h2>
                    <h2><i class="fa-brands fa-ethereum"></i> {{priceItem}}</h2>
                </div>
            <div class="controller">
                <div class="controller__amount">
                    <label for="aantal">Amount</label>
                    <a class="button" @click="less">-</a>
                    <input type="text" id="aantal" name="aantal" v-model="amountItem" min="1" :max="stock">
                    <a class="button" @click="add">+</a>

                </div>
                <i class="fa-solid fa-trash" @click="deleteItem"></i>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props:['pcz', 'name', 'image', 'price', 'amount', 'stock', 'maxStock'],
    data(){
        return{
            amountItem: 0,
            priceItem: 0,
        }
    },
    methods:{
        add(){
            if(this.amountItem < this.$props.maxStock){
                this.amountItem++;
                this.$emit("number-change", this.amountItem, this.$props.pcz);
            }
        },
        less(){
            if(this.amountItem <= 0){
                this.amountItem = 0;
                this.$emit("number-change", this.amountItem, this.$props.pcz);
            } else{
                this.amountItem--;
                this.$emit("number-change", this.amountItem, this.$props.pcz);
            }

        },
        deleteItem(){
            this.$emit("delete-item", this.$props.pcz);
        }
    },
    created(){
        this.amountItem = this.$props.amount;
    },
    watch:{
        amountItem(newAmount){
            if(newAmount > this.$props.maxStock){
                this.amountItem = this.$props.maxStock
            } else if(newAmount === 0){
                this.deleteItem();
            }
            this.priceItem = this.amountItem * this.$props.price;
            this.priceItem = this.priceItem.toFixed(1);
        }
    }
}
</script>

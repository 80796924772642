<template>
  <div class="specs">
    <label>Search by name</label><br />
      <input type="text" placeholder="Name..." v-model="pinguNameSearch" />
    <div class="specs-container" :id="specs">
      <div class="filter_collapse" @click="toggleTheme">
          <label>Theme ({{filters.themes.length}})</label>
          <i class="fa-solid fa-chevron-up"></i>
      </div>
        <div class="radio-button" :class="container_theme">
          <SelectFilter 
            @emit-filter="emitFilter"
            v-for="theme in filters.themes"
            :key="theme.id"
            :filterOption="theme"
            :tag="'1'"
          />
        </div>
      <br>
        <div class="filter_collapse" @click="toggleAttributes">
          <label>Attributes ({{filters.attributes.length}})</label>
          <i class="fa-solid fa-chevron-up"></i>
        </div>
        <div class="radio-button" :class="container_attributes">
          <SelectFilter 
            @emit-filter="emitFilter"
            v-for="attribute in filters.attributes"
            :key="attribute.id"
            :filterOption="attribute"
            :tag="'2'"
          />
        </div>
      <br>
      <div class="filter_collapse" @click="toggleHat">
          <label>Hats ({{filters.hats.length}})</label>
          <i class="fa-solid fa-chevron-up"></i>
      </div>
        <div class="radio-button" :class="container_hat">
          <SelectFilter 
            @emit-filter="emitFilter"
            v-for="hat in filters.hats"
            :key="hat.id"
            :filterOption="hat"
            :tag="'3'"
          />
      </div>
      <br>
      <div class="filter_collapse" @click="toggleBackpack">
          <label>Backpack ({{filters.backpacks.length}})</label>
          <i class="fa-solid fa-chevron-up"></i>
      </div>
        <div class="radio-button" :class="container_backpack">
          <SelectFilter 
            @emit-filter="emitFilter"
            v-for="backpack in filters.backpacks"
            :key="backpack.id"
            :filterOption="backpack"
            :tag="'6'"
          />
      </div>
      <br/>
      <div class="filter_collapse" @click="toggleFeathers">
          <label>Feathers ({{filters.feathers.length}})</label>
          <i class="fa-solid fa-chevron-up"></i>
      </div>
        <div class="radio-button" :class="container_feathers">
          <SelectFilter 
            @emit-filter="emitFilter"
            v-for="feather in filters.feathers"
            :key="feather.id"
            :filterOption="feather"
            :tag="'4'"
          />
      </div>
      <br/>
      <div class="filter_collapse" @click="toggleBeaks">
          <label>Beaks ({{filters.beaks.length}})</label>
          <i class="fa-solid fa-chevron-up"></i>
      </div>
        <div class="radio-button" :class="container_beaks">
          <SelectFilter 
            @emit-filter="emitFilter"
            v-for="beak in filters.beaks"
            :key="beak.id"
            :filterOption="beak"
            :tag="'5'"
          />
      </div>
      <br/>
        <label>Stock</label>
        <div class="radio-button">
          <SelectFilter 
            @emit-filter="emitFilter"
            v-for="stock in filters.stocks"
            :key="stock.id"
            :filterOption="stock"
            :tag="'7'"
          />
      </div>
      <br/>
      <a class="button" v-if="aantalFilters > 0" @click="emitRemove">Remove Filters {{ aantalFilters }}</a>
    </div>
  </div>
  <a class="button" id="shop-filter" @click="showFilters">{{ buttonText }}</a>
</template>
<script>
import SelectFilter from './SelectFilter.vue'
export default{
    components:{
      SelectFilter
    },
    inject:[
        'DataPCZ'
    ],
    props:["aantalFilters"],
    data(){
      return{
        specs: "specs_close",
        buttonText: "Show filters",
        container_theme: "filter_close",
        container_attributes: "filter_close",
        container_hat: "filter_close",
        container_feathers: "filter_close",
        container_beaks: "filter_close",
        container_backpack: "filter_close",
        pinguNameSearch: "",
        filters: {
          themes:{
            themes:[]
          },
          attributes:{
            attributes:[]
          },
          hats:{
            hats:[]
          },
          feathers:{
            feathers:[]
          },
          beaks:{
            beaks: []
          },
          backpacks:{
            backpacks: []
          },
          stocks:["Yes"]
        },
        checkedFilters:{
          attributes:[],
          hats:[]
        }
      }
    },
    methods:{
      emitFilter(selectedFilter){
        this.$emit("filter-collection", selectedFilter);
      },
      emitRemove(){
        this.$emit("remove-filters")
      },
      toggleTheme(){
        if(this.container_theme === "filter_close"){
            this.container_theme = "filter_open"
        } else{
            this.container_theme = "filter_close"
        }
      },
      toggleAttributes(){
        if(this.container_attributes === "filter_close"){
            this.container_attributes = "filter_open"
        } else{
            this.container_attributes = "filter_close"
        }
      },
      toggleHat(){
        if(this.container_hat === "filter_close"){
            this.container_hat = "filter_open"
        } else{
            this.container_hat = "filter_close"
        }
      },
      toggleFeathers(){
        if(this.container_feathers === "filter_close"){
            this.container_feathers = "filter_open"
        } else{
            this.container_feathers = "filter_close"
        }
      },
      toggleBeaks(){
        if(this.container_beaks === "filter_close"){
            this.container_beaks = "filter_open"
        } else{
            this.container_beaks = "filter_close"
        }
      },
      toggleBackpack(){
        if(this.container_backpack === "filter_close"){
            this.container_backpack = "filter_open"
        } else{
            this.container_backpack = "filter_close"
        }
      },
      showFilters(){
        if(this.specs === "specs_close"){
            this.specs = "specs_open"
            this.buttonText = "Hide filters"
        } else{
            this.specs = "specs_close"
            this.buttonText = "Show filters"
        }
      }
    },
    watch:{
      pinguNameSearch(newValue){
        console.log(newValue);
        this.$emit("zoek-naam", newValue);
      }
    },
    created(){
      // THEMES
      let themesAll = [];
      for (let i = 0; i < this.DataPCZ.length; i++) {
          themesAll.push(this.DataPCZ[i].tags.theme);
      }
      themesAll = themesAll.filter((item, index) => themesAll.indexOf(item) === index);
      themesAll.sort();
      this.filters.themes = themesAll;
      // ATTRIBUTES
      let attributeAll = [];
      for (let i = 0; i < this.DataPCZ.length; i++) {
          attributeAll.push(this.DataPCZ[i].tags.attribute);
      }
      attributeAll = attributeAll.filter((item, index) => attributeAll.indexOf(item) === index);
      attributeAll.sort();
      this.filters.attributes = attributeAll;
      // HATS
      let hatsAll = [];
      for (let i = 0; i < this.DataPCZ.length; i++) {
          hatsAll.push(this.DataPCZ[i].tags.hat);
      }
      hatsAll = hatsAll.filter((item, index) => hatsAll.indexOf(item) === index);
      hatsAll.sort();
      this.filters.hats = hatsAll;
      // FEATHERS
      let feathersAll = [];
      for (let i = 0; i < this.DataPCZ.length; i++) {
          feathersAll.push(this.DataPCZ[i].tags.feathers);
      }
      feathersAll = feathersAll.filter((item, index) => feathersAll.indexOf(item) === index);
      feathersAll.sort();
      this.filters.feathers = feathersAll;
      // BEAKS
      let beakAll = [];
      for (let i = 0; i < this.DataPCZ.length; i++) {
          beakAll.push(this.DataPCZ[i].tags.beak);
      }
      beakAll = beakAll.filter((item, index) => beakAll.indexOf(item) === index);
      beakAll.sort();
      this.filters.beaks = beakAll;
      // HATS
      let backpackAll = [];
      for (let i = 0; i < this.DataPCZ.length; i++) {
          backpackAll.push(this.DataPCZ[i].tags.backpack);
      }
      backpackAll = backpackAll.filter((item, index) => backpackAll.indexOf(item) === index);
      backpackAll.sort();
      this.filters.backpacks = backpackAll;
    }
}
</script>
<style>
.filter_open{
  display: block;
}
.filter_close{
  display: none;
}
@media screen and (max-width: 1200px) {
  #specs_close{
    display: none;
  }
  #specs_open{
    display: block;
  }
}
</style>
